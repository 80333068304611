import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "./Layout";

import * as styles from "./Post.module.css";

export const query = graphql
    `query PostByID(
        $slug: String!,
    ) {

        mdx(slug: {eq: $slug}) {
            body
            slug
            frontmatter {
                author {
                    friendlyName
                    role
                }
                title
                date(formatString: "MMM Do, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD HH:mm z")
                description
            }
        }
    }
`

const Post = ({ data, location }) => {

    const { body, frontmatter } = data.mdx;

    return (
        <Layout
            location={location}
            title={frontmatter.title}
            isBlog
        >
            <div>
                <div>
                    <p>{frontmatter.description}</p>

                    <div>
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>

                    <p>Sincerely,</p>
                    <h3 className={styles.name}>{frontmatter.author.friendlyName}</h3>
                    <h4 className={styles.role}>{frontmatter.author.role}</h4>
                </div>
            </div>
        </Layout>
    );
}

export default Post;